<template>
  <div class="alarm-dialog">
    <el-row :gutter="40">
      <el-col :span="12">
        <div class="information">
          <div class="head">
            <p>
              <span>车牌号：</span>
              <span>{{ detailForm.cph }}</span>
            </p>
            <p>
              <span>所属车队：</span>
              <span>{{ detailForm.companyName }}</span>
            </p>
            <p>
              <span>驾驶员：</span>
              <span class="sign">
                {{ detailForm.driverName }}
              </span>
            </p>
            <p>
              <span>中断时长：</span>
              <span class="sign"> {{ detailForm.interruptTime || 0 }}秒 </span>
            </p>
            <p>
              <span>行驶距离：</span>
              <span class="sign"> {{ detailForm.distance || 0 }}米 </span>
            </p>
            <p>
              <span>上报时间：</span>
              <span class="sign">
                {{ detailForm.beginPointTime }}
              </span>
            </p>
          </div>
          <div class="body">
            <pl-table
              :data="tableData"
              :stripe="true"
              :resizable="false"
              :border="false"
              height="auto"
              :highlight-current-row="false"
              ref="multipleTable"
              :row-height="45"
              :excess-rows="3"
              auto-resize
              @row-click="locatePoint"
              v-el-table-infinite-scroll="load"
            >
              <pl-table-column width="60" label="序号">
                <template slot-scope="{ $index }">
                  <span>{{ (page - 1) * pageSize + $index + 1 }}</span>
                </template>
              </pl-table-column>
              <pl-table-column
                prop="gt"
                label="定位时间"
                :width="180"
                show-overflow-tooltip
              ></pl-table-column>
              <pl-table-column
                prop="address"
                label="车辆位置"
                :width="160"
                show-overflow-tooltip
              ></pl-table-column>
              <pl-table-column
                prop="sl"
                label="速度/限速值"
                :width="100"
                show-overflow-tooltip
              ></pl-table-column>

              <pl-table-column
                prop="c"
                label="车辆状态"
                show-overflow-tooltip
              ></pl-table-column>
            </pl-table>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <p class="title">轨迹</p>
        <div class="map-style">
          <searchMap
            style="overflow: hidden"
            :system="system"
            :locatePoint="locatePointObj"
            ref="myMap"
          ></searchMap>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import elTableInfiniteScroll from "el-table-infinite-scroll";
import { PlTable, PlTableColumn } from "pl-table";
import {
  historyTrackNewAPI,
  queryDetailAPI,
} from "@/api/lib/gps-api.js";
import searchMap from './map.vue';
import { formatDate, oneGpsToLngLat } from '@/common/utils/index.js'
import getPlace from '@/common/utils/getPlace.js';

export default {
  components: {
    searchMap,
    PlTable,
    PlTableColumn,
  },
  directives: {
    "el-table-infinite-scroll": elTableInfiniteScroll,
  },
  props: ['detailData', 'queryRealType'],
  data () {
    return {
      fileForm: [],
      activeName: 'first',
      detailForm: {
        cph: '',
        companyName: '',
        driverName: '',
      },
      tableDataObj: {
        detailList: [],
        disposeAlarmData: []
      },
      // locatePointShow: null,//报警点地图定位点
      system: sessionStorage.getItem('system').toString(),
      tableData: [],
      singleDataAll: [],//原始数据
      page: 1,
      pageSize: 10,
      locatePointObj: {},
    }
  },
  filters: {
    filterProportion (item, limitValue) {
      let str = "-";
      if (limitValue && limitValue !== 0) {
        const poportion = (item - limitValue) / limitValue;
        str = `${Math.round(poportion * 100)}%`
      }
      return str
    }
  },
  methods: {
    /** 加载轨迹表格 */
    load () {
      this.$nextTick(() => {
        this.append();
      });
    },
    /* 每次滚动加载从数据仓库中取出10条数据 */
    append () {
      if (this.singleDataAll.length === 0) return;
      let arr = []
      let tenArr = [...this.singleDataAll.splice(0, 10)];//前十条数据
      for (let i = 0; i < tenArr.length; i++) {
        let s = new Promise((resolve, reject) => {
          oneGpsToLngLat(tenArr[i].lg, tenArr[i].lt, true).then((res) => {
            getPlace(res[0], res[1]).then((res) => {
              if (res) {
                tenArr[i].address = res
                resolve(tenArr)
              }
            })
          })
        })
        arr.push(s)
      }
      Promise.all(arr).then(res => {
        this.tableData = [
          ...this.tableData,
          ...tenArr,
        ];
      });
    },
    computedTime (time) {
      return formatDate(time);
    },
    //获取轨迹
    getAlarmTrace () {
      //结束时间需要多加1秒后台取得开区间
      let end = new Date(this.detailData.endPointTime).getTime() + 1000;
      const data = {
        system: this.system,
        beginTime: this.detailData.beginPointTime,
        endTime: formatDate(end),
        vehId:this.detailData.vehId,
        deviceType: 0
      };
      historyTrackNewAPI(data).then(res => {
        if (res.code === 1000) {
          this.singleDataAll = [...res.data.historyTrackList];
          let tenArr = [...this.singleDataAll.splice(0, 10)];
          let arr = [];
          for (let i = 0; i < tenArr.length; i++) {
            let s = new Promise((resolve, reject) => {
              oneGpsToLngLat(tenArr[i].lg, tenArr[i].lt, true).then((res) => {
                getPlace(res[0], res[1]).then((res) => {
                  if (res) {
                    tenArr[i].address = res
                    resolve(tenArr)
                  }
                })
              })
            })
            arr.push(s)
          }
          Promise.all(arr).then(res => {
            this.tableData = [...tenArr];
          })
          if (this.$refs.multipleTable) this.$refs.multipleTable.doLayout();
          //展示轨迹
          this.$refs.myMap.getHistoryTrack(res.data.historyTrackList || [], res.data.stops || [])//轨迹和停靠点绘制
        }
      })
    },
    /* 展示某个点的位置 */
    locatePoint (row) {
      this.locatePointObj = { ...row };
    },
    //获取详情
    getDetail () {
      const queryData = {
        travelContinuousId: this.detailData.id,
        queryRealType: this.queryRealType
      };
      queryDetailAPI(queryData).then(res => {
        if (res.code === 1000) {
          this.detailForm = res.data;
        } else {
          this.$message.warning(res.msg);
        }
      })
    },
  },
  mounted () {
    this.getDetail();
    this.getAlarmTrace();
  }
}
</script>
<style lang="scss" scoped>
.alarm-dialog {
  width: 99%;
  .map-style {
    width: 100%;
    height: calc(60vh - 24px);
  }
  .title {
    margin-bottom: 6px;
  }
  .pl-table {
    background: #ffffff;
    height: calc(60vh - 128px);
    width: 100%;
  }
  @include themify() {
    .information {
      padding: 12px 0;
      margin-bottom: 6px;
      .head {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        color: themed('n8');
        font-size: 14px;
        p {
          margin-bottom: 16px;
        }
      }
    }
  }
}
</style>