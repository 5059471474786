<template>
  <div class="">
    <el-form
      class="demo-form-inline"
      ref="form"
      :model="form"
      label-position="left"
      label-width="82px"
      :inline="true"
      :rules="rules"
    >
      <el-form-item label="所属车队：">
        <companySelectForSearch
          ref="companySelectForSearch"
          :searchable="true"
          @getValue="getGroupIds"
        />
      </el-form-item>
      <el-form-item label="车牌号：">
        <car-tree
          @getData="getVehIds"
          ref="carTree"
          :companyIdList="choosecph"
        ></car-tree>
      </el-form-item>
      <el-form-item label="开始时间：" prop="startTime">
        <el-date-picker
          v-model="form.startTime"
          type="datetime"
          placeholder="选择开始时间"
          :clearable="false"
          :picker-options="
            form.queryRealType == 2 ? pickerOptions : pickerOptionsOnline
          "
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="结束时间：" prop="finishTime">
        <el-date-picker
          v-model="form.finishTime"
          type="datetime"
          placeholder="选择结束时间"
          :clearable="false"
          :picker-options="
            form.queryRealType == 2 ? pickerOptions : pickerOptionsOnline
          "
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="中断(分钟)：">
        <el-input-number
          v-model="form.interruptTimeBegin"
          size="small"
          controls-position="right"
          :min="0"
        ></el-input-number
        >至<el-input-number
          v-model="form.interruptTimeEnd"
          controls-position="right"
          size="small"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="类型：">
        <el-select v-model="form.queryRealType" placeholder="请选择">
          <el-option
            v-for="item in queryRealTypeList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="距离(km)：">
        <el-input-number
          v-model="form.distanceMin"
          size="small"
          controls-position="right"
        ></el-input-number
        >至<el-input-number
          v-model="form.distanceMax"
          controls-position="right"
          size="small"
        ></el-input-number>
      </el-form-item>

      <el-form-item class="alarm-btn">
        <el-button size="small" type="primary" @click="search">查询</el-button>
        <slot></slot>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue';
import {
  checkTimeLimit,
  getStartDate,
  getCurentDate,
  formatDate,
} from '@/common/utils/index'
export default {
  components: {
    carTree,
    companySelectForSearch
  },
  props: {
    alarmStatusList: {
      type: Array,
      default: () => []
    },
    alarmOptions: {
      type: Array,
      default: () => []
    },
  },
  mounted () {
    this.search();
  },
  data () {
    let validateDateTime1 = (rule, value, callback) => {
      if (value != null && !checkTimeLimit(value, this.form.finishTime)) {
        callback(new Error('开始时间必须小于结束时间'))
      }
      //实时的话，不能大于当前时间      
      const result = new Date(value).valueOf() > new Date(Date.now()).valueOf();
      if (this.form.queryRealType === 1) {
        if (value != null && result) {
          callback(new Error('只能选择当前时间之前的时间'))
        } else {
          callback()
        }
      } else if (this.form.queryRealType === 2) {        
        const yesterday = new Date(getStartDate()).getTime();
        if (value != null && new Date(value).getTime() > yesterday) {
          callback(new Error('只能选择昨天之前的时间'))
        } else {
          callback()
        }
      }
      //历史的话需要选取昨天之前的时间
    }
    let validateDateTime2 = (rule, value, callback) => {
      if (value != null && !checkTimeLimit(this.form.startTime, value)) {
        callback(new Error('结束时间必须大于开始时间'))
      }
      //实时的话，不能大于当前时间      
      const result = new Date(value).valueOf() > new Date(Date.now()).valueOf();
      if (this.form.queryRealType === 1) {
        if (value != null && result) {
          callback(new Error('只能选择当前时间之前的时间'))
        } else {
          callback()
        }
      } else if (this.form.queryRealType === 2) {        
        const yesterday = new Date(getStartDate()).getTime();
        if (value != null && new Date(value).getTime() > yesterday) {
          callback(new Error('只能选择昨天之前的时间'))
        } else {
          callback()
        }
      }
    }
    return {
      choosecph: [],
      form: {
        vehicleNos: [],
        companyId: null,
        queryRealType: 1,//查询类型（1实时2历史）
        startTime: new Date(getStartDate()), // 开始时间,
        finishTime: new Date(getCurentDate()), // 结束时间,
      },
      queryRealTypeList: [
        {
          dictValue: "实时",
          dictCode: 1
        },
        {
          dictValue: "历史",
          dictCode: 2
        }
      ],
      //历史：只能选择就今天之前的时间  
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now() - 3600 * 1000 * 24;
        },
      },
      //实时只能选择今天当前时间之内
      pickerOptionsOnline: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24 || Date.now() < time.getTime();//禁掉未来时间
        }
      },
      rules: {
        finishTime: [
          { validator: validateDateTime2, trigger: 'blur' }
        ],
        startTime: [
          { validator: validateDateTime1, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 获取车辆数据
    getVehIds (val) {
      this.form.vehicleNos = [...val];
    },
    // 获取车组数据
    getGroupIds (val) {
      this.form.companyId = val;
    },
    search () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const data = { ...this.form };
          data.startTime = formatDate(this.form.startTime);
          data.finishTime = formatDate(this.form.finishTime);
          this.$emit("getFormVal", data);
        }
      })
    },
  }
}
</script>