var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "HistoricalContinuityData",
      staticClass: "historical-data-continuity"
    },
    [
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "serchForm",
            { on: { getFormVal: _vm.onSearch } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    disabled: _vm.tableData.length === 0,
                    loading: _vm.downLoadStatus
                  },
                  on: { click: _vm.exportData }
                },
                [_vm._v("导出")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: {
            id: "outputTable",
            data: _vm.tableData,
            stripe: "",
            height: _vm.tableHeight
          }
        },
        [
          _c("el-table-column", { attrs: { type: "index", label: "序号" } }),
          _c("el-table-column", {
            attrs: { prop: "companyName", label: "所属企业" }
          }),
          _c("el-table-column", {
            attrs: { prop: "cph", width: "110", label: "车牌号" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "address",
              label: "中断位置",
              width: "450",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "timeInterruptSlot",
              "show-overflow-tooltip": "",
              label: "中断时间"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "interruptTimeStr", label: "中断时长", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "distance", label: "行驶距离(m)", width: "140" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small", sort: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.onViewDetail(scope.row)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { ref: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.onCurrentChange
            }
          })
        ],
        1
      ),
      _vm.detailDialog
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "报警详情",
                visible: _vm.detailDialog,
                width: "75%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.detailDialog = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "body" },
                [
                  _c("historyDetailDialog", {
                    attrs: {
                      detailData: _vm.detailData,
                      queryRealType: _vm.form.queryRealType
                    }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }