
<template>
  <div class="map-content" style="height: calc(60vh - 24px)">
    <!-- 地图 -->
    <div
      id="container"
      class="monitoringMap"
    ></div>
  </div>
</template>

<script>
import {
  getStartDate,
  getCurentDate,
  formatDate,
  getTimeIsQualified,
  checkTimeLimitDay,
  checkLimitDate,
  compareAscSort
} from '@/common/utils/index'
import getPlace from '@/common/utils/getPlace.js'
import { YHTools } from '@/assets/js/Tools.js'
export default {
  components: {    
  },
  props: {
    locatePoint: {
      type: Object,
      default: function () {
        return {}
      }
    },
    /** 当前系统参数 */
    system: {
      type: [Number, String]
    },
    
  },
  data () {
    return {
      map: null,
      trailIcon: [
        require('@/assets/images/map/trailStart.png'), //起点
        require('@/assets/images/map/trailEnd.png'), //终点
        require('@/assets/images/map/trail.png') //停车点
      ],
      navgtr: null,      
      trailArr: [], // 轨迹点数据
      markers: [],
      path: [],
      pointArr: [], // 轨迹回放      
    }
  },
  methods: {
    initMap () {
      let map = new AMap.Map('container', {
        mapStyle: 'amap://styles/fresh',
        resizeEnable: true,
        expandZoomRange: true,
        // center: this.$store.state.user.lnglat,
        features: ['bg', 'road', 'building', 'point'],
        zoom: 12
      })
      this.map = map
    },
    /* 获取轨迹数据 */
    /**
     * 绘制线路
     * drawLine（）
     * 修改方式，两个点绘制两个点绘制
     */
    getHistoryTrack (res, stops) {
      let self = this
      if (res?.length > 0) {
        self.afterSearch = true;
        var arr = [], objArr = [], deviationArr = []; // 偏移线路点
        var positions = [res[0], ...stops] // 轨迹de开始，停靠点（stops），结束点
        for (let i = 0; i < res.length; i++) {
          const element = res[i]
          arr.push([element.lg, element.lt])
          objArr.push({
            longitude: element.lg,
            latitude: element.lt
          })
        }
        if (res.length > 1) {
          positions.push(res[res.length - 1])
        }
        self.trailArr = [...res] // 播放轨迹表单显示
        self.pointArr = arr // 轨迹线路
        self.deviationArr = deviationArr // 轨迹漂移
        self.initPathSimplifier()//播放轨迹
        self.drawLine(self.pointArr, positions, '', deviationArr) //画红色报警线
        // 展示线路  第二个参数:地图是否setFitView
      } else {
        // self.trailArr = [] // 播放轨迹表单显示清空
        // self.pointArr = [] // 轨迹线路清空
        self.$message({
          message: '暂无轨迹数据',
          type: 'warning'
        })
      }
    },


    /* 轨迹回放 绘制线路 */
    /**修改方式
     * 拿到所有的数据，画线段，根据报警状态区分线段颜色
     */
    drawLine (arr, positions, veh, deviationArr) {
      let self = this
      // 清除地图
      let polylines = []
      // 只有第一次进来的时候将arr赋值给this.lineArr 同时获取剪裁后的点
      self.lineArr = arr
      for (var i = 1; i < arr.length - 1; i++) {
        //ac有值表示报警定位点--绘制红线
        if (self.trailArr[i].ac) {
          polylines.push(self.returnLine([arr[i], arr[i + 1]], 'red'))
        }
      }
      /** 起始点marker */
      positions.forEach((item, i) => {
        let marker = new AMap.Marker({
          icon: new AMap.Icon({
            image:
              i === 0
                ? self.trailIcon[i]
                : i === positions.length - 1
                  ? self.trailIcon[1]
                  : self.trailIcon[2],
            imageSize: new AMap.Size(24, 30),
            size: new AMap.Size(30, 30) // 图标尺寸
          }),
          size: new AMap.Size(30, 30), // 图标尺寸
          zIndex: i === 0 || i === positions.length - 1 ? 200 : 100,
          position: [item.lg, item.lt]
        })
        /**
         * 注释的为之前停车点判断为速度为0，标记点数据来源来自轨迹
         * 后更改为停靠点逻辑为速度小于10的连续点
         */
        marker.on('click', function (e) {
          let area = ''
          getPlace(item.lg, item.lt).then((res) => {
            if (res) { area = res }           
            let content = 
              i === 0 || i === positions.length - 1
                ? `<div >时间：<span>${item.gt}</span></div>`
                : `<div >开始时间：<span>${item.startTime}</span></div>
              <div >结束时间：<span>${item.endTime}</span></div>
              <div >停车时长：<span>${item.pkt}</span></div>
              <div >相对里程：<span>${item.rm}公里</span></div>
              `
            let infoWindow = new AMap.InfoWindow({
              content: `<div class="trailBoard">
            ${content}
            <div style="color:#01adff;">${area}</div>
            </div>
            `
            })
            infoWindow.open(self.map, e.lnglat)
          })
        })
        marker.setMap(self.map)
      })
      // 缩放地图到合适的视野级别
      self.map.setFitView(polylines)
    },
    /** 创建polyline对象 */
    returnLine (spliceArr, color) {
      var polyline = new AMap.Polyline({
        map: this.map,
        path: spliceArr,
        borderWeight: 1,
        strokeColor: color,
        strokeOpacity: 1,
        strokeWeight: 3,
        strokeStyle: 'solid',
        strokeDasharray: [10, 5],
        lineJoin: 'round',
        lineCap: 'round',
        geodesic: true, // 绘制大地线
        zIndex: 50
      })
      return polyline
    },
    //播放轨迹
    initPathSimplifier () {
      var self = this
      AMapUI.load(
        ['ui/misc/PathSimplifier', 'lib/$'],
        function (PathSimplifier, $) {
          if (!PathSimplifier.supportCanvas) {
            alert('当前环境不支持 Canvas！')
            return
          }
          if (window.pathSimplifierIns) {
            //通过该方法清空上次传入的轨迹
            window.pathSimplifierIns.setData([])
          }
          var pathSimplifierIns = new PathSimplifier({
            zIndex: 100,
            // autoSetFitView:false,
            map: self.map, //所属的地图实例
            getPath: function (pathData, pathIndex) {
              return pathData.path
            },
            //鼠标悬停显示信息
            getHoverTitle: function (pathData, pathIndex, pointIndex) {
              if (pointIndex >= 0) {               
                if (self.trailArr[pointIndex].ac) {
                  return (
                    pointIndex +
                    1 +
                    '：' +
                    self.trailArr[pointIndex].gt +
                    '，' +
                    self.trailArr[pointIndex].v +
                    '公里/小时，' +
                    self.trailArr[pointIndex].ac
                  )
                } else {
                  return (
                    pointIndex +
                    1 +
                    '：' +
                    self.trailArr[pointIndex].gt +
                    '，' +
                    self.trailArr[pointIndex].v +
                    '公里/小时'
                  )
                }
              }
            },
            //轨迹样式
            renderOptions: {
              renderAllPointsIfNumberBelow: -1,
              pathTolerance: 0,
              keyPointTolerance: -1,
              pathLineStyle: {
                lineWidth: 3,
                strokeStyle: '#0B4FD5',
                dirArrowStyle: true
              },
              pathLineHoverStyle: {
                lineWidth: 3,
                strokeStyle: '#0B4FD5',
                dirArrowStyle: true
              },
              pathLineSelectedStyle: {
                lineWidth: 3,
                strokeStyle: '#0B4FD5',
                dirArrowStyle: true
              },
              dirArrowStyle: {
                stepSpace: 80,
                strokeStyle: '#ffffff',
                lineWidth: 2
              }
            }
          })
          window.pathSimplifierIns = pathSimplifierIns
          //设置数据
          pathSimplifierIns.setData([
            {
              name: '路线0',
              path: self.pointArr
            }
          ])
          pathSimplifierIns.setSelectedPathIndex(0)
          pathSimplifierIns.on('pointClick', function (e, info) {
            // console.log('Click: ' + self.trailArr[info.pointIndex].gt)
          })
          var pathNavigatorStyles = [
            {
              width: 18,
              height: 18,
              strokeStyle: '#0B4FD5',
              fillStyle: '#0B4FD5',
              pathLinePassedStyle: {
                lineWidth: 3,
                strokeStyle: '#AF5',
                dirArrowStyle: false
              }
            },
            {
              width: 18,
              height: 18,
              strokeStyle: 'red',
              fillStyle: 'red',
              pathLinePassedStyle: {
                lineWidth: 3,
                strokeStyle: '#AF5',
                dirArrowStyle: false
              }
            }
          ]
          function extend (dst) {
            if (!dst) {
              dst = {}
            }
            var slist = Array.prototype.slice.call(arguments, 1)
            for (var i = 0, len = slist.length; i < len; i++) {
              var source = slist[i]
              if (!source) {
                continue
              }
              for (var prop in source) {
                if (source.hasOwnProperty(prop)) {
                  dst[prop] = source[prop]
                }
              }
            }
            return dst
          }
          self.navgtr = null
          //创建一个巡航器
          self.navgtr = pathSimplifierIns.createPathNavigator(0, {
            loop: false, //循环播放
            speed: 10100, //巡航速度，单位千米/小时
            pathNavigatorStyle: extend({}, pathNavigatorStyles[0])           
          })
          self.navgtr.on('move', function (data, position) {

            let idx = position.dataItem.pointIndex //走到了第几个点
            // console.log('move',idx)
            self.replayform = self.trailArr[idx]
            //获取到pathNavigatorStyle的引用
            var pathNavigatorStyle = self.navgtr.getStyleOptions()
            //覆盖修改
            extend(
              pathNavigatorStyle,
              pathNavigatorStyles[self.trailArr[idx].ac === '' || self.trailArr[idx].ac == null ? 0 : 1]
            )
          })
        }
      )
    },
    getPath (arr) {
      let path
      path = {
        maxLongitude: arr.sort(compareAscSort('lng'))[3].lng,
        maxLatitude: arr.sort(compareAscSort('lat'))[3].lat,
        minLongitude: arr.sort(compareAscSort('lng'))[0].lng,
        minLatitude: arr.sort(compareAscSort('lat'))[0].lat
      }
      this.location = path
    },
  },
  created () { },
  mounted () {
    this.initMap();
  },
  watch: {
    /** 点击轨迹回放表格中位置信息 显示到地图上 */
    locatePoint (val) {
      this.map.remove(this.markers)
      var marker = new AMap.Marker({
        position: [val.lg, val.lt],
        zIndex: 201
      })
      marker.setMap(this.map)
      this.markers.push(marker)
      let self = this
      let area = ''
      let time = '';
      getPlace(val.lg, val.lt, true).then((res) => {
        if(res)area = res;
        val.gt?(time = val.gt):(time = val.startTime)
        let content = `<div >时间：<span>${time}</span></div>`
        let infoWindow = new AMap.InfoWindow({
          anchor: "top-left",
          content: `<div class=trailBoard>
            ${content}
            <div style=color:#059370;>${area}</div>
            </div>
            `
        })
        infoWindow.open(self.map, [val.lg, val.lt])
      })
      self.map.setCenter([val.lg, val.lt])
    },
  },
}
</script>

<style lang="scss" scoped>

@mixin active {
  color: themed('b4') !important;
  background-color: #eaf0fe !important;
  border-radius: 78px !important;
  i {
    color: themed('b4') !important;
  }
}

.map-content {
  position: relative;
  @include themify() {
    .monitoringMap {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
